
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "flowbite";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}

@font-face {
  font-family: 'misolight';
  src: local('misolight'),
       url('../src/assets/fonts/miso-light-webfont.woff2') format('woff2')
}

.carousel .thumbs-wrapper {
  display: flex;
  justify-content: center;
}

.carousel .thumbs {
  justify-content: center;
}

::-webkit-scrollbar {
  display: none;
}

h2{
  --tw-text-opacity: 1;
  color: #990011ff;
}

.heading {
  font-size: 50px;
  font-family: misolight;
  text-transform: uppercase;
  text-align: center;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}




/* .heading-background{
  background-image: linear-gradient(to right, #f31c12, #1ba6f7, #f8c100);
} */


